// src/axiosConfig.js
import axios from 'axios';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
    //   headers: {'Content-Type': 'application/json'}
    headers: {
        'Content-Type': 'application/json',
        'marketplace_id': process.env.REACT_APP_MARKETPLACE_ID, // Set the marketplace_id header
    }
});


export const axiosQAInstance = axios.create({
    baseURL: process.env.REACT_APP_QA_API_BASE_URL,
    timeout: 10000,
    //   headers: {'Content-Type': 'application/json'}
    headers: {
        'Content-Type': 'application/json',
        'marketplace_id': process.env.REACT_APP_MARKETPLACE_ID, // Set the marketplace_id header
    }
});

