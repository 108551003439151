import * as React from 'react';
import { Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../assets/css-logo.png';

const StyledImage = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '60vh',
}));

const StyledContentContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    textAlign: 'center',
    // marginTop: theme.spacing(8),
}));

const iconStyle = {
    // position: 'absolute',
    width: '50px',
    height: '50px',
    left: 'calc(50% - 25px)', // Center horizontally
    top: '110px', // Adjust vertical position as needed
    borderRadius: '50%', // Ensure it's a circle
    backgroundColor: '#f23535', // Circle background color
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)', // Optional: Add shadow for depth
};

export default function Error() {
    return (
        <>
            <Container>
                <StyledImage>
                    <img src={logo} alt='Logo' />
                </StyledImage>
            </Container>
            <StyledContainer>
                <StyledContentContainer>
                    <div style={iconStyle}>
                        <CloseIcon style={{ fontSize: '35px', color: 'white' }} />
                    </div>
                    <Typography variant="h4" style={{marginTop:20}}>
                        Error
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        Something went wrong
                    </Typography>
                    <Link href="/" underline="none" style={{ marginTop: '20px' }}>
                        <Typography style={{ color: '#26ACE2', fontWeight: '600' }}>
                            Back to Home
                        </Typography>
                    </Link>
                </StyledContentContainer>
            </StyledContainer>
        </>

    );
}
