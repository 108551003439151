import React, { useState } from 'react';
import {
    // Stepper,
    // Step,
    // StepLabel,
    // Button,
    Typography,
    Container
} from '@mui/material';
import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
// import StepThree from '../Pages/Success';
// import logo from '../assets/Logo.svg';
import logo from "../assets/css-logo.png";
import { styled } from '@mui/system';

const StyledImage = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

// const StyledStepper = styled(Stepper)(({ theme }) => ({
//     background: 'none',
//     padding: 0,
// }));

// const StyledStep = styled(Step)(({ theme }) => ({
//     '& .MuiStepIcon-root': {
//         color: '#675dff !important',
//     },
// }));

// const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
//     color: '#675dff !important',
// }));

export default function StepperUI() {
    const [activeStep, setActiveStep] = useState(0);
    const [formValues, setFormValues] = useState({});

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleFormData = (package_price, data) => {
        setFormValues({ ...data, package_price });
    };


    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <StepOne handleNextStep={handleNextStep} handleFormData={handleFormData} />;
            case 1:
                return <StepTwo formValues={formValues} />;
            // case 2:
            // return <StepThree />;
            default:
                return 'Unknown stepIndex';
        }
    };

    return (
        <div>
            <Container>
                <StyledImage>
                    <img src={logo} alt='Logo' />
                </StyledImage>
            </Container>
            {/* <StyledStepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <StyledStep key={label} completed={completedSteps[index]}>
                        <StyledStepLabel>{label}</StyledStepLabel>
                    </StyledStep>
                ))}
            </StyledStepper> */}
            <Typography>{getStepContent(activeStep)}</Typography>
            {/* <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography>All steps completed</Typography>
                    </div>
                ) : (
                    <div>
                        <div>
                            <Button disabled={activeStep === 0} onClick={handleBack}>
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNextStep}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div> */}
        </div>
    );
}
