import {axiosInstance} from "../config/axiosConfig";

export const getCountry = async () => {
  try {
    const response = await axiosInstance.get('/api/v2/public/countries?page=0&size=400');
    return response.data;
  } catch (error) {
    console.error('Error fetching demo data', error);
    throw error;
  }
};

export const getCity = async (countryID) => {
  try {
    const response = await axiosInstance.get(`/api/v2/public/cities?size=400&country_id=${countryID}&is_uae=false&page=0&status=active`);
    return response.data;
  } catch (error) {
    console.error('Error fetching demo data', error);
    throw error;
  }
};

export const getPromoValidate = async (params) => {
  try {
    const response = await axiosInstance.get(`/api/v2/public/promo?${params}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching demo data', error);
    throw error;
  }
};


export const getStartingFrom = async (params) => {
  try {
    const response = await axiosInstance.get(`/api/v2/public/packages/prices/starting_from?${params}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching demo data', error);
    throw error;
  }
};

export const getinitiateSession = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/public/my_fatoorah/initiate_session`);
    return response.data;
  } catch (error) {
    console.error('Error fetching demo data', error);
    throw error;
  }
};
