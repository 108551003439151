import React, { useState } from "react";
import { Button, Container, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import CustomerWalletCardPaymentForm from "../../components/form/CustomerWalletCardPaymentForm";
// import { MyLocation } from '@mui/icons-material';
import { styled } from "@mui/system";
import { myFatoorahCall } from "../../components/functions/MyFatoora";
import CircularProgress from "@mui/material/CircularProgress";

const StyledContainer = styled(Container)(({ theme }) => ({
  background: "#EAF7FD",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "30vh",
  padding: theme.spacing(2),
  borderRadius: "15px",
  marginTop: theme.spacing(2),
}));

const StyledContainerCheckout = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // background: 'white',
  marginBottom: theme.spacing(2),
}));

const StyledTextFeilds = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    background: "white",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& label.Mui-focused": {
    color: "black",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "19px",
  fontWeight: "500",
}));

const StyledBottomContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function StepTwo({ formValues }) {
  const [gatewaySession, setGatewaySession] = useState(null);
  const [formData, setFormData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePageChange = async () => {
    const payment_data = {
      session_id: gatewaySession,
      invoice_value: formValues.discount_value
        ? formValues.package_price.price.total - formValues.discount_value
        : formValues.package_price.price.total,
      call_back_url: `${window.location.origin}/success`,
      error_url: `${window.location.origin}/error`,
    };
    setLoading(true);
    try {
      window.myFatoorah
        .submit()
        // On success
        .then((response) => {
          console.log("RES::", response);
        })
        // In case of errors
        .catch((error) => {
          console.log(error); // eslint-disable-line no-console
          setLoading(false);
        });

      setTimeout(() => {
        try {
          myFatoorahCall(formValues, formData, payment_data)
            .then((response) => {
              console.log("Response:", response);
              setTimeout(() => {
                window.open(response.data.payment_url, "_self");
              }, 5000);
            })
            .catch((error) => {
              console.error("Error occurred during API call:", error); // Improved error logging
            })
            .finally(() => {
              setLoading(false); // End loading
            });
        } catch (error) {
          console.error("Unexpected error:", error); // Catch any synchronous errors
        }
      }, 5000);
    } catch (error) {
      console.error("Payment failed", error);
      setLoading(false);
    }
  };

  const handleGatewaySession = (session) => {
    setGatewaySession(session);
  };

  return (
    <>
      {/* <StyledContainer>
                <StyledTypography variant='h5'>
                    Add Sender and Recipient Information
                </StyledTypography>
            </StyledContainer> */}
      <StyledContainer>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              <StyledTypography variant="h5" gutterBottom>
                Sender
              </StyledTypography>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  className="inputLabels" // Add your own class here if needed
                  size="small"
                  // id="outlined-basic"
                  label="Name"
                  name="sender_name"
                  value={formData.sender_name}
                  onChange={(event) =>
                    handleChange("sender_name", event.target.value)
                  }
                  // variant="standard"
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  className="inputLabels" // Add your own class here if needed
                  size="small"
                  // id="outlined-basic"
                  label="Address"
                  // variant="standard"
                  name="sender_address"
                  value={formData.sender_address}
                  onChange={(event) =>
                    handleChange("sender_address", event.target.value)
                  }
                  // InputProps={{
                  //     endAdornment: (
                  //         <InputAdornment position="end">
                  //             <MyLocation />
                  //         </InputAdornment>
                  //     )
                  // }}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  className="inputLabels" // Add your own class here if needed
                  size="small"
                  // id="outlined-basic"
                  label="Phone#"
                  name="sender_phone"
                  value={formData.sender_phone}
                  onChange={(event) =>
                    handleChange("sender_phone", event.target.value)
                  }
                  // variant="standard"
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  className="inputLabels" // Add your own class here if needed
                  size="small"
                  // id="outlined-basic"
                  label="Email"
                  name="sender_email"
                  value={formData.sender_email}
                  onChange={(event) =>
                    handleChange("sender_email", event.target.value)
                  }
                  // variant="standard"
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <StyledTypography variant="h5" gutterBottom>
                Recipient
              </StyledTypography>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  className="inputLabels" // Add your own class here if needed
                  size="small"
                  name="recipient_name"
                  value={formData.recipient_name}
                  onChange={(event) =>
                    handleChange("recipient_name", event.target.value)
                  }
                  // id="outlined-basic"
                  label="Name"
                  // variant="standard"
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  className="inputLabels" // Add your own class here if needed
                  size="small"
                  // id="outlined-basic"
                  label="Address"
                  name="recipient_address"
                  value={formData.recipient_address}
                  onChange={(event) =>
                    handleChange("recipient_address", event.target.value)
                  }
                  // variant="standard"
                  // InputProps={{
                  //     endAdornment: (
                  //         <InputAdornment position="end">
                  //             <MyLocation />
                  //         </InputAdornment>
                  //     )
                  // }}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  className="inputLabels" // Add your own class here if needed
                  size="small"
                  name="recipient_phone"
                  value={formData.recipient_phone}
                  onChange={(event) =>
                    handleChange("recipient_phone", event.target.value)
                  }
                  // id="outlined-basic"
                  label="Phone#"
                  // variant="standard"
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  className="inputLabels" // Add your own class here if needed
                  size="small"
                  name="recipient_email"
                  value={formData.recipient_email}
                  onChange={(event) =>
                    handleChange("recipient_email", event.target.value)
                  }
                  // id="outlined-basic"
                  label="Email"
                  // variant="standard"
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
      <StyledContainerCheckout>
        <StyledTypography className="checkout" variant="h5">
          Checkout
        </StyledTypography>
      </StyledContainerCheckout>
      <StyledContainer>
        <Box sx={{ flexGrow: 1 }}>
          <StyledTypography variant="h5" gutterBottom>
            Card Details
          </StyledTypography>
          <CustomerWalletCardPaymentForm
            handleGatewaySession={handleGatewaySession}
          />
        </Box>
      </StyledContainer>
      <StyledBottomContainer>
        {/* <Button
                    size="small"
                    variant='contained'
                    style={{ backgroundColor: '#675dff', color: 'white', height: '50%', padding: '10px 20px', borderRadius: '0' }}
                    onClick={handleBack}
                >
                    Back
                </Button> */}
        <Button
          size="small"
          variant="contained"
          style={{
            backgroundColor: "#26ACE2",
            color: "white",
            height: "50%",
            padding: "10px 20px",
            borderRadius: "0",
            position: "relative",
          }}
          onClick={handlePageChange}
          disabled={loading}
        >
          {loading ? <CircularProgress size={18} color="inherit" /> : "Pay Now"}
        </Button>
      </StyledBottomContainer>
    </>
  );
}
