import { Routes, Route } from 'react-router-dom';
import './App.css';
import StepperUI from './StepperForm/StepperUI';
import Success from './Pages/Success';
import Error from './Pages/Error';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<StepperUI />} />
        <Route path='/success' element={<Success />} />
        <Route path='/error' element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
